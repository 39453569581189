<template>
  <div>
    <team-accomplish :type="0" :diff="$route.query.diff" :examine="false" :new="true"></team-accomplish>
  </div>
</template>

<script>
import TeamAccomplish from "components/TeamAccomplish";
export default {
  name: "Freshers",
  components: {
    TeamAccomplish
  }
}
</script>

<style scoped>

</style>
